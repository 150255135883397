.suggestion-items {
    padding: 0.2rem;
    position: relative;
    border-radius: 0.5rem;
    background: #fff;
    color: rgba(0, 0, 0, 0.8);
    overflow: hidden;
    font-size: 0.9rem;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.1);
}

.suggestion-item {
    display: block;
    margin: 0;
    width: 100%;
    // text-align: left;
    // background: transparent;
    // border-radius: 0.4rem;
    // border: 1px solid transparent;
    // padding: 0.2rem 0.4rem;

    &.is-selected {
        border-color: #000;
    }
}
