.tag {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
        "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
    font-weight: 600;
    padding: 3px 6px;
    display: inline-flex;
    align-items: center;
    // border-radius: 20px;
    border-radius: 4px;
    line-height: 100%;
    cursor: pointer;
    width: max-content;
    font-style: initial !important;

    background: #e9eaec;
    border: 1px dashed rgba(106, 108, 125, 0.2);
    color: #6a6c7d;
    white-space: pre-line;
    &--selected {
        border-color: #6a6c7d;
        border-style: solid;
    }
    &--highlighted {
        border-color: #6a6c7d;
        border-style: solid;
    }
}

// .tag::selection {
//     color: red;
//     background-color: yellow;
// }

// .tag--selected {
//   border-color: red;
//   border-style: solid;
// }

// .tag--selected:after {
//     content: "✏️";
//     margin-left: 10px;
// }

// .tag:before {
//     content: url("drag-handle.svg");
//     // content: "🔗";
//     margin-right: 10px;
// }

.tag-relevance {
    background: #f3fff9;
    border: 1px dashed #cee8db;
    color: #259b5e;
    &--selected {
        border-color: #259b5e;
        border-style: solid;
    }
    &--highlighted {
        border-color: #259b5e;
        border-style: solid;
    }
}

.tag-theme {
    background: #f3fff9;
    border: 1px dashed #cee8db;
    border-radius: 20px;
    color: #259b5e;
    &--selected {
        border-color: #259b5e;
        border-style: solid;
    }
    &--highlighted {
        border-color: #259b5e;
        border-style: solid;
    }
}

.tag-subtheme {
    background: #52d93d08;
    border: 1px dashed #52d93d20;
    border-radius: 20px;
    color: #52d93d;
    &--selected {
        border-color: #52d93d;
        border-style: solid;
    }
    &--highlighted {
        border-color: #52d93d;
        border-style: solid;
    }
}

.tag-issue {
    background: #bc397008;
    border: 1px dashed #bc397020;
    border-radius: 20px;
    color: #bc3970;
    &--selected {
        border-color: #bc3970;
        border-style: solid;
    }
    &--highlighted {
        border-color: #bc3970;
        border-style: solid;
    }
}

.tag-keyfact {
    background: #eee7ef;
    border: 1px dashed #ba61c820;
    border-radius: 20px;
    color: #ba61c8;
    &--selected {
        border-color: #ba61c8;
        border-style: solid;
    }
    &--highlighted {
        border-color: #ba61c8;
        border-style: solid;
    }
}

.tag-causesofaction {
    background: #d6e5e8;
    border: 1px dashed #3597ad20;
    border-radius: 20px;
    color: #3597ad;
    &--selected {
        border-color: #3597ad;
        border-style: solid;
    }
    &--highlighted {
        border-color: #3597ad;
        border-style: solid;
    }
}

.tag-causesofactionelement,
.tag-causesofactiondefenseelement {
    background: #e0f0f3;
    border: 1px dashed #0ed4ff20;
    border-radius: 20px;
    color: #0ed4ff;
    &--selected {
        border-color: #0ed4ff;
        border-style: solid;
    }
    &--highlighted {
        border-color: #0ed4ff;
        border-style: solid;
    }
}

.tag-paper {
    background: #ff00f510;
    border: 1px dashed #ff00f510;
    border-radius: 20px;
    color: #ff00f5;
    &--selected {
        border-color: #ff00f5;
        border-style: solid;
    }
    &--highlighted {
        border-color: #ff00f5;
        border-style: solid;
    }
}

.tag-evidence {
    background: rgba(55, 74, 194, 0.08);
    border: 1px solid rgba(55, 74, 194, 0.1);
    color: #374ac2;
    &--selected {
        border-color: #374ac2;
        border-style: solid;
    }
    &--highlighted {
        border-color: #374ac2;
        border-style: solid;
    }
}

.tag-witness {
    background: rgba(241, 120, 182, 0.08);
    border: 1px solid rgba(241, 120, 182, 0.1);
    color: #f178b6;
    &--selected {
        border-color: #f178b6;
        border-style: solid;
    }
    &--highlighted {
        border-color: #f178b6;
        border-style: solid;
    }
}

.tag-keysection {
    background: rgba(233, 229, 243, 0.5);
    border: 1px dashed rgba(127, 72, 244, 0.2);
    color: #7f48f4;
    border-radius: 20px;
    &--selected {
        border-color: #7f48f4;
        border-style: solid;
    }
    &--highlighted {
        border-color: #7f48f4;
        border-style: solid;
    }
}

.tag-objective {
    background: rgba(243, 235, 225, 0.5);
    border: 1px dashed rgba(255, 144, 23, 0.2);
    border-radius: 20px;
    color: #ff9017;
    &--selected {
        border-color: #ff9017;
        border-style: solid;
    }
    &--highlighted {
        border-color: #ff9017;
        border-style: solid;
    }
}

.tag-topic {
    background: white;
    border: 1px solid rgba(15, 18, 47, 0.2);
    color: #0f122f;
    &--selected {
        border-color: #0f122f;
        border-style: solid;
    }
    &--highlighted {
        border-color: #0f122f;
        border-style: solid;
    }
}

.tag-examline {
    border-radius: 20px;
}

.tag-examline {
    background: #e9eaec;
    border: 1px dashed rgba(106, 108, 125, 0.2);
    color: #6a6c7d;
    border-radius: 20px;
    &--selected {
        border-color: #6a6c7d;
        border-style: solid;
    }
    &--highlighted {
        border-color: #6a6c7d;
        border-style: solid;
    }
}

.tag-party {
    background: rgba(255, 0, 0, 0.1);
    border: 1px dashed rgba(55, 74, 194, 0.1);
    color: #ff0000;
    &--selected {
        border-color: #ff0000;
        border-style: solid;
    }
    &--highlighted {
        border-color: #ff0000;
        border-style: solid;
    }
}

.tag-authority {
    background: rgba(15, 203, 203, 0.08);
    border: 1px solid rgba(15, 203, 203, 0.1);
    color: #0fcbcb;
    &--selected {
        border-color: #0fcbcb;
        border-style: solid;
    }
    &--highlighted {
        border-color: #0fcbcb;
        border-style: solid;
    }
}

.tag-comment {
    background: rgba(128, 130, 45, 0.1);
    border: 1px dashed rgba(128, 130, 45, 0.1);
    color: #80822d;
    &--selected {
        border-color: #80822d;
        border-style: solid;
    }
    &--highlighted {
        border-color: #80822d;
        border-style: solid;
    }
}

.color--relevance,
.color--theme {
    color: #259b5e;
}

.color--evidence {
    color: #374ac2;
}

.color--witness {
    color: #f178b6;
}

.color--keysection {
    color: #7f48f4;
}

.color--objective {
    color: #ff9017;
}

.color--topic {
    color: #0f122f;
}

.color--examline {
    color: #6a6c7d;
}

.color--party {
    color: #ff0000;
}

.color--authority {
    color: #0fcbcb;
}

.color--comment {
    color: #80822d;
}

tag-creation {
    font-weight: 600;
    padding: 3px 6px;
    // border-radius: 20px;
    border-radius: 4px;
    display: inline-block;
    line-height: 100%;
    cursor: pointer;

    background: #e9eaec;
    border: 1px dashed rgba(106, 108, 125, 0.2);
    color: #6a6c7d;
    background: rgba(55, 74, 194, 0.08);
    border: 1px solid rgba(55, 74, 194, 0.1);
    color: #374ac2;
    padding-left: 20px;
    position: relative;
}

tag-creation:before {
    // content: "🔗";
    content: url("data:image/svg+xml,%3Csvg width='8' height='16' viewBox='0 0 8 16' xmlns='http://www.w3.org/2000/svg' class='fill--evidence' style='min-width: 8px; width: 8px; height: 16px; margin-right: 10px; opacity: 0.5;'%3E%3Cellipse cx='1.2' cy='13.9275' rx='1.2' ry='1.11111'%3E%3C/ellipse%3E%3Cellipse cx='6.79961' cy='13.9275' rx='1.2' ry='1.11111'%3E%3C/ellipse%3E%3Cellipse cx='1.2' cy='8.00174' rx='1.2' ry='1.11111'%3E%3C/ellipse%3E%3Cellipse cx='6.79961' cy='8.00174' rx='1.2' ry='1.11111'%3E%3C/ellipse%3E%3Cellipse cx='1.2' cy='2.07595' rx='1.2' ry='1.11111'%3E%3C/ellipse%3E%3Cellipse cx='6.79961' cy='2.07595' rx='1.2' ry='1.11111'%3E%3C/ellipse%3E%3C/svg%3E");

    margin-right: 10px;
    position: absolute;
    left: 6px;
    opacity: 0.5;
}

.fill--relevance,
.fill--theme {
    fill: #259b5e;
}

.fill--evidence {
    fill: #374ac2;
}

.fill--witness {
    fill: #f178b6;
}

.fill--keysection {
    fill: #7f48f4;
}

.fill--objective {
    fill: #ff9017;
}

.fill--topic {
    fill: #0f122f;
}

.fill--examline {
    fill: #6a6c7d;
}

.fill--party {
    fill: #ff0000;
}

.fill--authority {
    fill: #0fcbcb;
}

.fill--comment {
    fill: #80822d;
}

span.react-renderer {
    vertical-align: middle;
}

.tag * {
    pointer-events: none;
}

.color--theme {
    color: #259b5e;
}

.color--subtheme {
    color: #52d93d;
}

.color--issue {
    color: #bc3970;
}

.color--keyfact {
    color: #ba61c8;
}

.color--causesofaction {
    color: #3597ad;
}

.color--causesofactiondefenseelement,
.color--causesofactionelement {
    color: #0ed4ff;
}

.color--paper {
    color: #ff00f5;
}

.fill--theme {
    fill: #259b5e;
}

.fill--subtheme {
    fill: #52d93d;
}

.fill--issue {
    fill: #bc3970;
}

.fill--keyfact {
    fill: #ba61c8;
}

.fill--causesofaction {
    fill: #3597ad;
}

.fill--causesofactiondefenseelement,
.fill--causesofactionelement {
    fill: #0ed4ff;
}

.fill--paper {
    fill: #ff00f5;
}

#case-library .tag {
    max-width: 100%;
}

.node-creation,
.node-evidence,
.node-tag {
    // position: relative;
    display: inline-flex;
    margin-top: -2px;
}

.node-creation {
    // position: relative;
    display: inline-flex;
    margin-top: -2px;
}

::-moz-selection {
    background-color: #2593fc !important;
    color: white !important;
}

::selection {
    background-color: #2593fc !important;
    color: white !important;
}

.ant-switch-checked {
    background: #374ac2 !important;
}

// .ant-popover-inner-content {
//     padding: 20px !important;
// }
