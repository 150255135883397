p {
    // margin: 0;
}

.ProseMirror {
    outline: none;
    height: 100%;
    max-height: 100%;
    overflow: auto;
    // max-height: calc(100% - 24px);
    line-height: 200%;
    padding: 36px;
    font-size: 15px;

    > * + * {
        // margin-top: 0.75em;
    }

    ul,
    ol {
        padding: 0 1rem;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        line-height: 1.1;
    }

    code {
        background-color: rgba(#616161, 0.1);
        color: #616161;
    }

    pre {
        background: #0d0d0d;
        color: #fff;
        font-family: "JetBrainsMono", monospace;
        padding: 0.75rem 1rem;
        border-radius: 0.5rem;

        code {
            color: inherit;
            padding: 0;
            background: none;
            font-size: 0.8rem;
        }
    }

    img {
        max-width: 100%;
        height: auto;
    }

    blockquote {
        padding-left: 1rem;
        border-left: 2px solid rgba(#0d0d0d, 0.1);
    }

    hr {
        border: none;
        border-top: 2px solid rgba(#0d0d0d, 0.1);
        margin: 2rem 0;
    }
}

.workspace-editor {
    height: calc(100% - 67px);
}

input[type="color"] {
    -webkit-appearance: none;
    border: none;
    width: 28px;
    height: 28px;
}
input[type="color"]::-webkit-color-swatch-wrapper {
    padding: 0;
}
input[type="color"]::-webkit-color-swatch {
    border: none;
}

.ProseMirror ul {
    list-style-type: disc;
}

.ProseMirror {
    table {
        border-collapse: collapse;
        margin: 0;
        overflow: hidden;
        table-layout: fixed;
        width: 100%;

        td,
        th {
            border: 2px solid #ced4da;
            box-sizing: border-box;
            min-width: 1em;
            padding: 3px 5px;
            position: relative;
            vertical-align: top;

            > * {
                margin-bottom: 0;
            }
        }

        th {
            background-color: #f1f3f5;
            font-weight: 500;
            text-align: left;
        }

        .selectedCell:after {
            background: rgba(200, 200, 255, 0.4);
            content: "";
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            pointer-events: none;
            position: absolute;
            z-index: 2;
        }

        .column-resize-handle {
            background-color: #adf;
            bottom: -2px;
            position: absolute;
            right: -2px;
            pointer-events: none;
            top: 0;
            width: 4px;
        }

        p {
            margin: 0;
        }
    }
}
.resize-cursor {
    cursor: ew-resize;
    cursor: col-resize;
}
.tableWrapper {
    padding: 1rem 0;
    overflow-x: auto;
}

// .ProseMirror {
//     table,
//     caption,
//     tbody,
//     tfoot,
//     thead,
//     tr,
//     th,
//     td {
//         all: revert;
//     }
//     table {
//         all: revert;
//         min-width: 100%;
//     }
//     tr,
//     th,
//     td {
//         // border: 1px solid black !important;
//     }
// }

.ProseMirror img {
    max-height: 300px;
}

.TrialHubDropdownFilter {
    margin-left: -15px;
    margin-right: -15px;
}

.add-chapter-form {
    .TrialHubDropdownFilter {
        margin-left: -15px;
        margin-right: -15px;
        margin-bottom: 15px;
    }
    .TrialHubDropdownFilter__control {
        background: white !important;
    }
}

.TrialHubDropdownFilter__placeholder {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

// .notebook-popup:after {
//     content: "";
//     width: 20px;
//     height: 20px;
//     transform: rotate(-45deg);
//     background: #fff;
//     position: absolute;
//     box-shadow: 1px 4px 8px rgba(0, 0, 0, 0.5);
//     z-index: -1;
//     bottom: -10px;
//     left: calc(50% - 10px);
// }

// content: "";
// width: 20px;
// height: 20px;
// transform: rotate(-45deg);
// background: #fff;
// position: absolute;
// /* box-shadow: 1px 4px 8px rgb(0 0 0 / 50%); */
// z-index: -1;
// bottom: -5px;
// left: calc(50% - 10px);
// z-index: -1;
// border-bottom: 1px solid #dcdce6;
// border-left: 1px solid #dcdce6;
// /* right: -10px; */
// margin-right: -10px;
// margin-left: 29px;

.ProseMirror {
    table:after {
        content: "+ New";
        color: #374ac2;
        font-weight: 500;
        position: absolute;
        margin-top: 4px;
        cursor: pointer;
    }
    table {
        margin-bottom: 20px;
    }
}
