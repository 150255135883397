// Fonts
@import url("https://fonts.googleapis.com/css?family=Nunito");

// Variables
@import "variables";
@import "./tags.scss";
@import "./tiptap.scss";
@import "./suggestions-list.scss";

// Bootstrap
@import "~bootstrap/scss/bootstrap";
